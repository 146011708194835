import { Carousel, Footer, Header, Hero, HeroFullScreen, PreviewForm, RenderClient } from '~modules'
import { Button, CarouselSlide, Divider, Form, Heading, Kicker, Modal, Pagination, Paragraph, Vimeo } from '~elements'

export const components = {
  Button,
  Carousel,
  CarouselSlide,
  Divider,
  Footer,
  Form,
  Header,
  Heading,
  Hero,
  HeroFullScreen,
  Kicker,
  Modal,
  Pagination,
  Paragraph,
  PreviewForm,
  RenderClient,
  Vimeo
}
